import { graphql } from 'gatsby';
import React from 'react';

import Hero from 'components/pages/about/hero';
import Sections from 'components/pages/about/sections';
import Team from 'components/pages/about/team';
import BookADemo from 'components/shared/book-a-demo';
import MainLayout from 'layouts/main';

const About = ({
  data: {
    wpPage: { seo, acf: data },
  },
  pageContext,
}) => (
  <MainLayout seo={seo} pageContext={pageContext}>
    <Hero {...data.aboutHero} />
    <Sections items={data.sections} />
    <Team {...data.team} />
    <BookADemo />
  </MainLayout>
);

export default About;

export const query = graphql`
  query ($id: String) {
    wpPage(id: { eq: $id }) {
      title
      uri
      acf {
        aboutHero {
          title
          description
        }
        sections {
          title
          description
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(height: 350)
              }
            }
          }
        }
        team {
          title
          description
          items {
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 96)
                }
              }
            }
            name
            position
            companies {
              name
            }
          }
        }
      }
      ...wpPageSeo
    }
  }
`;
