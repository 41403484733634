import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

const Item = ({ image: localFile, name, position, companies }) => {
  const image = localFile.childImageSharp;

  return (
    <div className="flex items-center text-center sm:text-left sm:items-start flex-col sm:flex-row bg-secondary-1 rounded-4xl p-7 pb-4">
      <div className="flex items-center justify-center flex-shrink-0 mr-4 md:mr-8 h-26 w-26 md:h-32 md:w-32 border-4 border-primary-1 rounded-full overflow-hidden">
        <GatsbyImage
          className="h-24 w-24"
          imgClassName="rounded-full"
          image={getImage(image)}
          alt={name}
        />
      </div>
      <div className="mt-4 md:mt-0">
        <Heading tag="h3" size="md" theme="white" fontWeight="extra">
          {name}
        </Heading>
        <span className="block font-bold text-primary-1 mt-1 font-cursive text-xxl">
          {position}
        </span>
        <div className="mt-4">
          {companies.map(({ name }) => (
            <div
              className="relative inline-flex text-white font-bold mr-3 mb-3 last:mr-0 py-1.5 px-3.5 rounded-[24px] overflow-hidden text-sm xl:text-base"
              key={name}
            >
              <span className="absolute top-0 left-0 h-full w-full bg-white opacity-5" />

              {name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Team = ({ title, description, items }) => {
  const leftColElements = items.slice(0, Math.ceil(items.length / 2));
  const rightColElements = items.slice(-Math.floor(items.length / 2));

  return (
    <section className="mt-28 md:mt-40">
      <Container>
        <div className="grid grid-cols-12 md:gap-x-10">
          <div className="col-start-1 col-end-13 xl:col-start-2 xl:col-end-12">
            <Heading className="uppercase tracking-wide" tag="h2" size="lg">
              {title}
            </Heading>
            <p className="font-semibold max-w-[635px] xl:max-w-[716px] text-xl xl:text-xxl mt-5">
              {description}
            </p>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-11 mt-10 md:mt-14">
              <div className="grid gap-y-11">
                {leftColElements.map(({ image: { localFile }, ...props }) => (
                  <Item image={localFile} {...props} key={props.name} />
                ))}
              </div>

              <div className="grid gap-y-11 mt-11 lg:mt-0 lg:translate-y-20">
                {rightColElements.map(({ image: { localFile }, ...props }) => (
                  <Item image={localFile} title={title} {...props} key={props.name} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

Team.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.objectOf(PropTypes.any).isRequired,
      name: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      companies: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
};

export default Team;
