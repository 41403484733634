import classNames from 'classnames';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

import Line1 from './images/line-1.inline.svg';
import Line2 from './images/line-2.inline.svg';

const Section = ({ title, description, image: { localFile, altText: imageAltText }, index }) => {
  const isFirst = index === 0;
  const isEven = (index + 1) % 2 === 0;

  const image = localFile.childImageSharp;

  return (
    <>
      <div className="hidden md:block">
        {isFirst && <Line1 className="relative left-1/2 transform -translate-x-1/2 ml-[-157px]" />}
        {isEven && <Line2 className="relative left-1/2 transform -translate-x-1/2 max-w-full" />}
        {!isFirst && !isEven && (
          <Line2 className="relative scale-x-[-1] left-1/2 transform -translate-x-1/2" />
        )}
      </div>

      <section className="grid md:grid-cols-2 items-center md:gap-x-10 mt-16 md:mt-0" key={index}>
        <div
          className={classNames('relative ', {
            'order-1 md:order-2': isEven,
          })}
        >
          <div className="h-[280px] md:h-[350px] overflow-hidden">
            <GatsbyImage
              className="w-full h-full"
              imgClassName="rounded-4xl xl:rounded-5xl"
              image={getImage(image)}
              alt={imageAltText}
            />
          </div>
        </div>
        <div
          className={classNames('mt-6 md:mt-0', {
            'order-1': isEven,
          })}
        >
          {title && (
            <Heading className="text-2xl xl:text-4xl mb-3 xl:mb-6" tag="h2">
              {title}
            </Heading>
          )}

          <p className="text-sm lg:text-base xl:text-xl">{description}</p>
        </div>
      </section>
    </>
  );
};

const Sections = ({ items }) => (
  <div>
    <Container>
      <div className="grid grid-cols-12 md:gap-x-10">
        <div className="relative col-start-1 col-end-13 xl:col-start-2 xl:col-end-12">
          {items?.map((props, index) => (
            <Section {...props} index={index} key={index} />
          ))}
        </div>
      </div>
    </Container>
  </div>
);

Sections.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string.isRequired,
      image: PropTypes.objectOf(PropTypes.any).isRequired,
    })
  ).isRequired,
};

export default Sections;
