import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

import LinesIcon from './images/lines.inline.svg';
import LogoIcon from './images/logo.inline.svg';

const Hero = ({ title, description }) => (
  <section className="pb-[72px] header-offset">
    <Container>
      <div className="relative bg-secondary-9 rounded-4xl xl:rounded-5xl pt-16 px-4 md:px-8 lg:pt-16 lg:px-16 pb-24 xl:pb-32">
        <LinesIcon className="hidden lg:block w-auto h-[75px] xl:h-[120px] 2xl:h-28 2xl:h-auto absolute top-0 left-0" />
        <LinesIcon className="hidden lg:block w-auto h-[75px] xl:h-[120px] 2xl:h-28 2xl:h-auto xl:h-auto absolute top-0 right-0 rotate-90" />
        <div className="relative max-w-[970px] mx-auto">
          <Heading className="text-center uppercase tracking-wide" tag="h1" size="lg">
            {title}
          </Heading>
          <p className="mt-3.5 text-center font-semibold text-xl xl:text-xxl">{description}</p>
        </div>

        <div className="absolute -bottom-18 left-1/2 transform -translate-x-1/2 flex items-center justify-center h-36 w-36 bg-black rounded-7xl">
          <LogoIcon className="-mt-2" />
        </div>
      </div>
    </Container>
  </section>
);

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Hero;
